import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import sync from "./store/reducers/sync";
import auth from "./store/reducers/auth";
import toast from "./store/reducers/toast";
import user from "./store/reducers/user";
import event from "./store/reducers/event";
import chart from "./store/reducers/chart";
import alert from "./store/reducers/alert";
import spinnerModal from "./store/reducers/spinnerModal";
import {IonReactRouter} from "@ionic/react-router";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

defineCustomElements(window);

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers =
    (process.env.NODE_ENV === "development"
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : null) || compose;

const rootReducer = combineReducers({
    sync,
    auth,
    toast,
    user,
    event,
    alert,
    chart,
    spinnerModal
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

const app = (
    <Provider store={store}>
        <IonReactRouter>
            <App/>
        </IonReactRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
