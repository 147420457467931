import React from 'react';

import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";

type ProtectedRouteProps = {
    component?: React.Component | React.FunctionComponent | React.ReactNode;
    path: string;
    exact?: boolean;
    render?: any
};

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = (props) => {
    const {component: Component, render, path, ...rest} = props;
    const isAuthenticated = useSelector((state: any) => state.auth.token || null);

    let renderFunction = (props: any) => {
        if (isAuthenticated) {
            // @ts-ignore
            return <Component {...props} />;
        } else {
            return <Redirect to="/login"/>;
        }
    };

    if (render && isAuthenticated) {
        renderFunction = render;
    }

    return (
        <Route
            path={path}
            {...rest}
            render={renderFunction}
        />
    );
};

export default ProtectedRoute;
