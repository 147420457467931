import personal_data from "./1_personal_data";
import declaration from "./2_declaration";
import privacy from "./3_privacy";
import signatures from "./4_signatures";
import {FormInterface} from "../../../../declarations";

const personal: FormInterface[] = [
    {
        form_name: "Biztosítotti személyi adatai",
        form_data: personal_data
    },
    {
        form_name: "Biztosítotti nyilatkozat",
        form_data: declaration,
        info: '(A nyilatkozatok csak a jelölőnégyzet kitöltésével együtt érvényesek!)'
    },
    {
        form_name: "Nyilatkozatok",
        form_data: privacy,
        info: 'A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási\n' +
            'kötelezettség alóli felmentéssel kapcsolatos nyilatkozata\n' +
            'STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz'
    },
    {
        form_name: "Aláírások",
        form_data: signatures,
    }
];

export default personal;
