import {FormDataInterface} from "../../../../declarations";
import {defaultFormConfig} from "../../helper/helper";

const personal_data: FormDataInterface = {
    first_name: {
        label: 'Vezetéknév',
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    last_name: {
        label: 'Keresztnév',
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    status: {
        label: 'Tanuló',
        text: 'Státusz',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: false,
        },
        colSize: '6',
        ...defaultFormConfig
    },
    sex: {
        label: 'Nem',
        elementType: 'radio',
        elementConfig: {
            options: [
                {
                    displayName: 'Nő',
                    optionValue: 'Nő'
                },
                {
                    displayName: 'Férfi',
                    optionValue: 'Férfi'
                }
            ]
        },
        validation: {
            required: true,
        },
        colSize: '6',
        ...defaultFormConfig
    },
    date_of_birth: {
        label: 'Születési dátum',
        elementType: 'date',
        elementConfig: {},
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig,
    },
    citizenship: {
        label: 'Állampolgárság',
        elementType: 'select',
        multiple: false,
        elementConfig: {
            options: [
                {
                    "displayName": "Afghanistan",
                    "optionValue": "Afghanistan"
                },
                {
                    "displayName": "Åland Islands",
                    "optionValue": "Åland Islands"
                },
                {
                    "displayName": "Albania",
                    "optionValue": "Albania"
                },
                {
                    "displayName": "Algeria",
                    "optionValue": "Algeria"
                },
                {
                    "displayName": "American Samoa",
                    "optionValue": "American Samoa"
                },
                {
                    "displayName": "AndorrA",
                    "optionValue": "AndorrA"
                },
                {
                    "displayName": "Angola",
                    "optionValue": "Angola"
                },
                {
                    "displayName": "Anguilla",
                    "optionValue": "Anguilla"
                },
                {
                    "displayName": "Antarctica",
                    "optionValue": "Antarctica"
                },
                {
                    "displayName": "Antigua and Barbuda",
                    "optionValue": "Antigua and Barbuda"
                },
                {
                    "displayName": "Argentina",
                    "optionValue": "Argentina"
                },
                {
                    "displayName": "Armenia",
                    "optionValue": "Armenia"
                },
                {
                    "displayName": "Aruba",
                    "optionValue": "Aruba"
                },
                {
                    "displayName": "Australia",
                    "optionValue": "Australia"
                },
                {
                    "displayName": "Austria",
                    "optionValue": "Austria"
                },
                {
                    "displayName": "Azerbaijan",
                    "optionValue": "Azerbaijan"
                },
                {
                    "displayName": "Bahamas",
                    "optionValue": "Bahamas"
                },
                {
                    "displayName": "Bahrain",
                    "optionValue": "Bahrain"
                },
                {
                    "displayName": "Bangladesh",
                    "optionValue": "Bangladesh"
                },
                {
                    "displayName": "Barbados",
                    "optionValue": "Barbados"
                },
                {
                    "displayName": "Belarus",
                    "optionValue": "Belarus"
                },
                {
                    "displayName": "Belgium",
                    "optionValue": "Belgium"
                },
                {
                    "displayName": "Belize",
                    "optionValue": "Belize"
                },
                {
                    "displayName": "Benin",
                    "optionValue": "Benin"
                },
                {
                    "displayName": "Bermuda",
                    "optionValue": "Bermuda"
                },
                {
                    "displayName": "Bhutan",
                    "optionValue": "Bhutan"
                },
                {
                    "displayName": "Bolivia",
                    "optionValue": "Bolivia"
                },
                {
                    "displayName": "Bosnia and Herzegovina",
                    "optionValue": "Bosnia and Herzegovina"
                },
                {
                    "displayName": "Botswana",
                    "optionValue": "Botswana"
                },
                {
                    "displayName": "Bouvet Island",
                    "optionValue": "Bouvet Island"
                },
                {
                    "displayName": "Brazil",
                    "optionValue": "Brazil"
                },
                {
                    "displayName": "British Indian Ocean Territory",
                    "optionValue": "British Indian Ocean Territory"
                },
                {
                    "displayName": "Brunei Darussalam",
                    "optionValue": "Brunei Darussalam"
                },
                {
                    "displayName": "Bulgaria",
                    "optionValue": "Bulgaria"
                },
                {
                    "displayName": "Burkina Faso",
                    "optionValue": "Burkina Faso"
                },
                {
                    "displayName": "Burundi",
                    "optionValue": "Burundi"
                },
                {
                    "displayName": "Cambodia",
                    "optionValue": "Cambodia"
                },
                {
                    "displayName": "Cameroon",
                    "optionValue": "Cameroon"
                },
                {
                    "displayName": "Canada",
                    "optionValue": "Canada"
                },
                {
                    "displayName": "Cape Verde",
                    "optionValue": "Cape Verde"
                },
                {
                    "displayName": "Cayman Islands",
                    "optionValue": "Cayman Islands"
                },
                {
                    "displayName": "Central African Republic",
                    "optionValue": "Central African Republic"
                },
                {
                    "displayName": "Chad",
                    "optionValue": "Chad"
                },
                {
                    "displayName": "Chile",
                    "optionValue": "Chile"
                },
                {
                    "displayName": "China",
                    "optionValue": "China"
                },
                {
                    "displayName": "Christmas Island",
                    "optionValue": "Christmas Island"
                },
                {
                    "displayName": "Cocos (Keeling) Islands",
                    "optionValue": "Cocos (Keeling) Islands"
                },
                {
                    "displayName": "Colombia",
                    "optionValue": "Colombia"
                },
                {
                    "displayName": "Comoros",
                    "optionValue": "Comoros"
                },
                {
                    "displayName": "Congo",
                    "optionValue": "Congo"
                },
                {
                    "displayName": "Congo, The Democratic Republic of the",
                    "optionValue": "Congo, The Democratic Republic of the"
                },
                {
                    "displayName": "Cook Islands",
                    "optionValue": "Cook Islands"
                },
                {
                    "displayName": "Costa Rica",
                    "optionValue": "Costa Rica"
                },
                {
                    "displayName": "Cote D\"Ivoire",
                    "optionValue": "Cote D\"Ivoire"
                },
                {
                    "displayName": "Croatia",
                    "optionValue": "Croatia"
                },
                {
                    "displayName": "Cuba",
                    "optionValue": "Cuba"
                },
                {
                    "displayName": "Cyprus",
                    "optionValue": "Cyprus"
                },
                {
                    "displayName": "Czech Republic",
                    "optionValue": "Czech Republic"
                },
                {
                    "displayName": "Denmark",
                    "optionValue": "Denmark"
                },
                {
                    "displayName": "Djibouti",
                    "optionValue": "Djibouti"
                },
                {
                    "displayName": "Dominica",
                    "optionValue": "Dominica"
                },
                {
                    "displayName": "Dominican Republic",
                    "optionValue": "Dominican Republic"
                },
                {
                    "displayName": "Ecuador",
                    "optionValue": "Ecuador"
                },
                {
                    "displayName": "Egypt",
                    "optionValue": "Egypt"
                },
                {
                    "displayName": "El Salvador",
                    "optionValue": "El Salvador"
                },
                {
                    "displayName": "Equatorial Guinea",
                    "optionValue": "Equatorial Guinea"
                },
                {
                    "displayName": "Eritrea",
                    "optionValue": "Eritrea"
                },
                {
                    "displayName": "Estonia",
                    "optionValue": "Estonia"
                },
                {
                    "displayName": "Ethiopia",
                    "optionValue": "Ethiopia"
                },
                {
                    "displayName": "Falkland Islands (Malvinas)",
                    "optionValue": "Falkland Islands (Malvinas)"
                },
                {
                    "displayName": "Faroe Islands",
                    "optionValue": "Faroe Islands"
                },
                {
                    "displayName": "Fiji",
                    "optionValue": "Fiji"
                },
                {
                    "displayName": "Finland",
                    "optionValue": "Finland"
                },
                {
                    "displayName": "France",
                    "optionValue": "France"
                },
                {
                    "displayName": "French Guiana",
                    "optionValue": "French Guiana"
                },
                {
                    "displayName": "French Polynesia",
                    "optionValue": "French Polynesia"
                },
                {
                    "displayName": "French Southern Territories",
                    "optionValue": "French Southern Territories"
                },
                {
                    "displayName": "Gabon",
                    "optionValue": "Gabon"
                },
                {
                    "displayName": "Gambia",
                    "optionValue": "Gambia"
                },
                {
                    "displayName": "Georgia",
                    "optionValue": "Georgia"
                },
                {
                    "displayName": "Germany",
                    "optionValue": "Germany"
                },
                {
                    "displayName": "Ghana",
                    "optionValue": "Ghana"
                },
                {
                    "displayName": "Gibraltar",
                    "optionValue": "Gibraltar"
                },
                {
                    "displayName": "Greece",
                    "optionValue": "Greece"
                },
                {
                    "displayName": "Greenland",
                    "optionValue": "Greenland"
                },
                {
                    "displayName": "Grenada",
                    "optionValue": "Grenada"
                },
                {
                    "displayName": "Guadeloupe",
                    "optionValue": "Guadeloupe"
                },
                {
                    "displayName": "Guam",
                    "optionValue": "Guam"
                },
                {
                    "displayName": "Guatemala",
                    "optionValue": "Guatemala"
                },
                {
                    "displayName": "Guernsey",
                    "optionValue": "Guernsey"
                },
                {
                    "displayName": "Guinea",
                    "optionValue": "Guinea"
                },
                {
                    "displayName": "Guinea-Bissau",
                    "optionValue": "Guinea-Bissau"
                },
                {
                    "displayName": "Guyana",
                    "optionValue": "Guyana"
                },
                {
                    "displayName": "Haiti",
                    "optionValue": "Haiti"
                },
                {
                    "displayName": "Heard Island and Mcdonald Islands",
                    "optionValue": "Heard Island and Mcdonald Islands"
                },
                {
                    "displayName": "Holy See (Vatican City State)",
                    "optionValue": "Holy See (Vatican City State)"
                },
                {
                    "displayName": "Honduras",
                    "optionValue": "Honduras"
                },
                {
                    "displayName": "Hong Kong",
                    "optionValue": "Hong Kong"
                },
                {
                    "displayName": "Hungary",
                    "optionValue": "Hungary"
                },
                {
                    "displayName": "Iceland",
                    "optionValue": "Iceland"
                },
                {
                    "displayName": "India",
                    "optionValue": "India"
                },
                {
                    "displayName": "Indonesia",
                    "optionValue": "Indonesia"
                },
                {
                    "displayName": "Iran, Islamic Republic Of",
                    "optionValue": "Iran, Islamic Republic Of"
                },
                {
                    "displayName": "Iraq",
                    "optionValue": "Iraq"
                },
                {
                    "displayName": "Ireland",
                    "optionValue": "Ireland"
                },
                {
                    "displayName": "Isle of Man",
                    "optionValue": "Isle of Man"
                },
                {
                    "displayName": "Israel",
                    "optionValue": "Israel"
                },
                {
                    "displayName": "Italy",
                    "optionValue": "Italy"
                },
                {
                    "displayName": "Jamaica",
                    "optionValue": "Jamaica"
                },
                {
                    "displayName": "Japan",
                    "optionValue": "Japan"
                },
                {
                    "displayName": "Jersey",
                    "optionValue": "Jersey"
                },
                {
                    "displayName": "Jordan",
                    "optionValue": "Jordan"
                },
                {
                    "displayName": "Kazakhstan",
                    "optionValue": "Kazakhstan"
                },
                {
                    "displayName": "Kenya",
                    "optionValue": "Kenya"
                },
                {
                    "displayName": "Kiribati",
                    "optionValue": "Kiribati"
                },
                {
                    "displayName": "Korea, Democratic People\"S Republic of",
                    "optionValue": "Korea, Democratic People\"S Republic of"
                },
                {
                    "displayName": "Korea, Republic of",
                    "optionValue": "Korea, Republic of"
                },
                {
                    "displayName": "Kuwait",
                    "optionValue": "Kuwait"
                },
                {
                    "displayName": "Kyrgyzstan",
                    "optionValue": "Kyrgyzstan"
                },
                {
                    "displayName": "Lao People\"S Democratic Republic",
                    "optionValue": "Lao People\"S Democratic Republic"
                },
                {
                    "displayName": "Latvia",
                    "optionValue": "Latvia"
                },
                {
                    "displayName": "Lebanon",
                    "optionValue": "Lebanon"
                },
                {
                    "displayName": "Lesotho",
                    "optionValue": "Lesotho"
                },
                {
                    "displayName": "Liberia",
                    "optionValue": "Liberia"
                },
                {
                    "displayName": "Libyan Arab Jamahiriya",
                    "optionValue": "Libyan Arab Jamahiriya"
                },
                {
                    "displayName": "Liechtenstein",
                    "optionValue": "Liechtenstein"
                },
                {
                    "displayName": "Lithuania",
                    "optionValue": "Lithuania"
                },
                {
                    "displayName": "Luxembourg",
                    "optionValue": "Luxembourg"
                },
                {
                    "displayName": "Macao",
                    "optionValue": "Macao"
                },
                {
                    "displayName": "Macedonia, The Former Yugoslav Republic of",
                    "optionValue": "Macedonia, The Former Yugoslav Republic of"
                },
                {
                    "displayName": "Madagascar",
                    "optionValue": "Madagascar"
                },
                {
                    "displayName": "Malawi",
                    "optionValue": "Malawi"
                },
                {
                    "displayName": "Malaysia",
                    "optionValue": "Malaysia"
                },
                {
                    "displayName": "Maldives",
                    "optionValue": "Maldives"
                },
                {
                    "displayName": "Mali",
                    "optionValue": "Mali"
                },
                {
                    "displayName": "Malta",
                    "optionValue": "Malta"
                },
                {
                    "displayName": "Marshall Islands",
                    "optionValue": "Marshall Islands"
                },
                {
                    "displayName": "Martinique",
                    "optionValue": "Martinique"
                },
                {
                    "displayName": "Mauritania",
                    "optionValue": "Mauritania"
                },
                {
                    "displayName": "Mauritius",
                    "optionValue": "Mauritius"
                },
                {
                    "displayName": "Mayotte",
                    "optionValue": "Mayotte"
                },
                {
                    "displayName": "Mexico",
                    "optionValue": "Mexico"
                },
                {
                    "displayName": "Micronesia, Federated States of",
                    "optionValue": "Micronesia, Federated States of"
                },
                {
                    "displayName": "Moldova, Republic of",
                    "optionValue": "Moldova, Republic of"
                },
                {
                    "displayName": "Monaco",
                    "optionValue": "Monaco"
                },
                {
                    "displayName": "Mongolia",
                    "optionValue": "Mongolia"
                },
                {
                    "displayName": "Montserrat",
                    "optionValue": "Montserrat"
                },
                {
                    "displayName": "Morocco",
                    "optionValue": "Morocco"
                },
                {
                    "displayName": "Mozambique",
                    "optionValue": "Mozambique"
                },
                {
                    "displayName": "Myanmar",
                    "optionValue": "Myanmar"
                },
                {
                    "displayName": "Namibia",
                    "optionValue": "Namibia"
                },
                {
                    "displayName": "Nauru",
                    "optionValue": "Nauru"
                },
                {
                    "displayName": "Nepal",
                    "optionValue": "Nepal"
                },
                {
                    "displayName": "Netherlands",
                    "optionValue": "Netherlands"
                },
                {
                    "displayName": "Netherlands Antilles",
                    "optionValue": "Netherlands Antilles"
                },
                {
                    "displayName": "New Caledonia",
                    "optionValue": "New Caledonia"
                },
                {
                    "displayName": "New Zealand",
                    "optionValue": "New Zealand"
                },
                {
                    "displayName": "Nicaragua",
                    "optionValue": "Nicaragua"
                },
                {
                    "displayName": "Niger",
                    "optionValue": "Niger"
                },
                {
                    "displayName": "Nigeria",
                    "optionValue": "Nigeria"
                },
                {
                    "displayName": "Niue",
                    "optionValue": "Niue"
                },
                {
                    "displayName": "Norfolk Island",
                    "optionValue": "Norfolk Island"
                },
                {
                    "displayName": "Northern Mariana Islands",
                    "optionValue": "Northern Mariana Islands"
                },
                {
                    "displayName": "Norway",
                    "optionValue": "Norway"
                },
                {
                    "displayName": "Oman",
                    "optionValue": "Oman"
                },
                {
                    "displayName": "Pakistan",
                    "optionValue": "Pakistan"
                },
                {
                    "displayName": "Palau",
                    "optionValue": "Palau"
                },
                {
                    "displayName": "Palestinian Territory, Occupied",
                    "optionValue": "Palestinian Territory, Occupied"
                },
                {
                    "displayName": "Panama",
                    "optionValue": "Panama"
                },
                {
                    "displayName": "Papua New Guinea",
                    "optionValue": "Papua New Guinea"
                },
                {
                    "displayName": "Paraguay",
                    "optionValue": "Paraguay"
                },
                {
                    "displayName": "Peru",
                    "optionValue": "Peru"
                },
                {
                    "displayName": "Philippines",
                    "optionValue": "Philippines"
                },
                {
                    "displayName": "Pitcairn",
                    "optionValue": "Pitcairn"
                },
                {
                    "displayName": "Poland",
                    "optionValue": "Poland"
                },
                {
                    "displayName": "Portugal",
                    "optionValue": "Portugal"
                },
                {
                    "displayName": "Puerto Rico",
                    "optionValue": "Puerto Rico"
                },
                {
                    "displayName": "Qatar",
                    "optionValue": "Qatar"
                },
                {
                    "displayName": "Reunion",
                    "optionValue": "Reunion"
                },
                {
                    "displayName": "Romania",
                    "optionValue": "Romania"
                },
                {
                    "displayName": "Russian Federation",
                    "optionValue": "Russian Federation"
                },
                {
                    "displayName": "RWANDA",
                    "optionValue": "RWANDA"
                },
                {
                    "displayName": "Saint Helena",
                    "optionValue": "Saint Helena"
                },
                {
                    "displayName": "Saint Kitts and Nevis",
                    "optionValue": "Saint Kitts and Nevis"
                },
                {
                    "displayName": "Saint Lucia",
                    "optionValue": "Saint Lucia"
                },
                {
                    "displayName": "Saint Pierre and Miquelon",
                    "optionValue": "Saint Pierre and Miquelon"
                },
                {
                    "displayName": "Saint Vincent and the Grenadines",
                    "optionValue": "Saint Vincent and the Grenadines"
                },
                {
                    "displayName": "Samoa",
                    "optionValue": "Samoa"
                },
                {
                    "displayName": "San Marino",
                    "optionValue": "San Marino"
                },
                {
                    "displayName": "Sao Tome and Principe",
                    "optionValue": "Sao Tome and Principe"
                },
                {
                    "displayName": "Saudi Arabia",
                    "optionValue": "Saudi Arabia"
                },
                {
                    "displayName": "Senegal",
                    "optionValue": "Senegal"
                },
                {
                    "displayName": "Serbia and Montenegro",
                    "optionValue": "Serbia and Montenegro"
                },
                {
                    "displayName": "Seychelles",
                    "optionValue": "Seychelles"
                },
                {
                    "displayName": "Sierra Leone",
                    "optionValue": "Sierra Leone"
                },
                {
                    "displayName": "Singapore",
                    "optionValue": "Singapore"
                },
                {
                    "displayName": "Slovakia",
                    "optionValue": "Slovakia"
                },
                {
                    "displayName": "Slovenia",
                    "optionValue": "Slovenia"
                },
                {
                    "displayName": "Solomon Islands",
                    "optionValue": "Solomon Islands"
                },
                {
                    "displayName": "Somalia",
                    "optionValue": "Somalia"
                },
                {
                    "displayName": "South Africa",
                    "optionValue": "South Africa"
                },
                {
                    "displayName": "South Georgia and the South Sandwich Islands",
                    "optionValue": "South Georgia and the South Sandwich Islands"
                },
                {
                    "displayName": "Spain",
                    "optionValue": "Spain"
                },
                {
                    "displayName": "Sri Lanka",
                    "optionValue": "Sri Lanka"
                },
                {
                    "displayName": "Sudan",
                    "optionValue": "Sudan"
                },
                {
                    "displayName": "Suriname",
                    "optionValue": "Suriname"
                },
                {
                    "displayName": "Svalbard and Jan Mayen",
                    "optionValue": "Svalbard and Jan Mayen"
                },
                {
                    "displayName": "Swaziland",
                    "optionValue": "Swaziland"
                },
                {
                    "displayName": "Sweden",
                    "optionValue": "Sweden"
                },
                {
                    "displayName": "Switzerland",
                    "optionValue": "Switzerland"
                },
                {
                    "displayName": "Syrian Arab Republic",
                    "optionValue": "Syrian Arab Republic"
                },
                {
                    "displayName": "Taiwan, Province of China",
                    "optionValue": "Taiwan, Province of China"
                },
                {
                    "displayName": "Tajikistan",
                    "optionValue": "Tajikistan"
                },
                {
                    "displayName": "Tanzania, United Republic of",
                    "optionValue": "Tanzania, United Republic of"
                },
                {
                    "displayName": "Thailand",
                    "optionValue": "Thailand"
                },
                {
                    "displayName": "Timor-Leste",
                    "optionValue": "Timor-Leste"
                },
                {
                    "displayName": "Togo",
                    "optionValue": "Togo"
                },
                {
                    "displayName": "Tokelau",
                    "optionValue": "Tokelau"
                },
                {
                    "displayName": "Tonga",
                    "optionValue": "Tonga"
                },
                {
                    "displayName": "Trinidad and Tobago",
                    "optionValue": "Trinidad and Tobago"
                },
                {
                    "displayName": "Tunisia",
                    "optionValue": "Tunisia"
                },
                {
                    "displayName": "Turkey",
                    "optionValue": "Turkey"
                },
                {
                    "displayName": "Turkmenistan",
                    "optionValue": "Turkmenistan"
                },
                {
                    "displayName": "Turks and Caicos Islands",
                    "optionValue": "Turks and Caicos Islands"
                },
                {
                    "displayName": "Tuvalu",
                    "optionValue": "Tuvalu"
                },
                {
                    "displayName": "Uganda",
                    "optionValue": "Uganda"
                },
                {
                    "displayName": "Ukraine",
                    "optionValue": "Ukraine"
                },
                {
                    "displayName": "United Arab Emirates",
                    "optionValue": "United Arab Emirates"
                },
                {
                    "displayName": "United Kingdom",
                    "optionValue": "United Kingdom"
                },
                {
                    "displayName": "United States",
                    "optionValue": "United States"
                },
                {
                    "displayName": "United States Minor Outlying Islands",
                    "optionValue": "United States Minor Outlying Islands"
                },
                {
                    "displayName": "Uruguay",
                    "optionValue": "Uruguay"
                },
                {
                    "displayName": "Uzbekistan",
                    "optionValue": "Uzbekistan"
                },
                {
                    "displayName": "Vanuatu",
                    "optionValue": "Vanuatu"
                },
                {
                    "displayName": "Venezuela",
                    "optionValue": "Venezuela"
                },
                {
                    "displayName": "Viet Nam",
                    "optionValue": "Viet Nam"
                },
                {
                    "displayName": "Virgin Islands, British",
                    "optionValue": "Virgin Islands, British"
                },
                {
                    "displayName": "Virgin Islands, U.S.",
                    "optionValue": "Virgin Islands, U.S."
                },
                {
                    "displayName": "Wallis and Futuna",
                    "optionValue": "Wallis and Futuna"
                },
                {
                    "displayName": "Western Sahara",
                    "optionValue": "Western Sahara"
                },
                {
                    "displayName": "Yemen",
                    "optionValue": "Yemen"
                },
                {
                    "displayName": "Zambia",
                    "optionValue": "Zambia"
                },
                {
                    "displayName": "Zimbabwe",
                    "optionValue": "Zimbabwe"
                },
            ]
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    mobile_phone: {
        label: 'Telefonszám',
        elementType: 'input',
        elementConfig: {
            type: 'number'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    email: {
        label: 'E-mail cím / E-mail address',
        elementType: 'input',
        elementConfig: {
            type: 'email'
        },
        validation: {
            required: true,
            isEmail: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    policy_no: {
        label: 'Szerződésszám',
        elementType: 'input',
        elementConfig: {
            type: 'number'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    om_id: {
        label: 'OM azonosító',
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    text: {
        label: 'Biztosítotti jogviszony, biztosítási időszak',
        elementType: 'text',
        text: 'A Biztosítotti jogviszony a Biztosított Semmelweis Egyetemmel fennálló aktív hallgatói jogviszonyának fennállása időtartamára jön létre, feltéve, hogy a Semmelweis Egyetem a Biztosítónak Biztosítottként bejelentette és a rá vonatkozó biztosítási díjat az adott biztosítási időszakra megfizette. A biztosítási időszakok fél évesek, minden tárgyévben szeptember 1. napjától a következő naptári év február 28. napjáig, illetve március 1. napjától augusztus 31. napjáig tartanak',
        elementConfig: {},
        validation: {
            required: false,
        },
        ...defaultFormConfig
    }
};

export default personal_data;
