import React, {useCallback, useEffect, useState} from 'react';
import {
    IonButton,
    IonContent,
    IonPage,
    useIonViewDidEnter,
    useIonViewDidLeave,
} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useSelector} from "react-redux";

import Header from "../../../components/Header/Header";
import {ChartInterface,  LooseObject} from "../../../store/declarations";
import Spinner from "../../../components/Spinner/Spinner";
import {getChartByHash} from "../../../utils/functions/chart";
import classes from "./Pdf.module.scss";

interface PdfProps extends RouteComponentProps<{
    chartHash: string;
}> {
}

const Pdf: React.FC<PdfProps> = (props) => {
    const charts: ChartInterface[] = useSelector((state: any) => state.chart.charts);
    const isChartsLoading: boolean = useSelector((state: any) => state.chart.loading);

    const {match} = props;
    const [loaded, updateLoaded] = useState(false);
    const [fields, updateFields] = useState<LooseObject>({});
    const getCurrentChart = useCallback(() => getChartByHash(charts, match.params.chartHash), [charts, match]);

    useIonViewDidEnter(() => {
        updateLoaded(false);
        setTimeout(() => {
            updateLoaded(true);
        }, 1);
    });

    const initChart = useCallback(() => {
        const chart = getCurrentChart();

        if (chart !== null) {
            updateFields(setFieldObject(chart));
        }
    }, [getCurrentChart]);

    const setFieldObject = (chart: ChartInterface) => {
        let fields: LooseObject = {};

        // TODO: FINISH
        // chart.input_fields.forEach((field: InputFieldInterface) => {
        //     fields[field.name] = field.value;
        // });

        return fields;
    };

    useEffect(() => {
        if (!isChartsLoading) {
            initChart();
        }
    }, [initChart, isChartsLoading]);

    useIonViewDidLeave(() => {
        updateLoaded(false);
    });

    const openFile = () => {

    };

    const showTemplate = () => {
        if (!loaded || isChartsLoading) {
            return <Spinner/>
        }

        return (
            <div className="ion-padding">
                <h1 className="ion-padding-bottom">Páciens neve: {fields.last_name} {fields.first_name}</h1>

                <div className={classes['button-group']}>
                    <IonButton onClick={openFile}>
                        Adatkezelési nyilatkozat letöltése
                    </IonButton>

                    <IonButton>
                        <a target="_blank" rel="noopener noreferrer"
                           href={process.env.REACT_APP_API_BASE_URL + '/api/v1/pdf/user'}>Páciens adatainak
                            letöltése</a>
                    </IonButton>

                    <IonButton>
                        <a target="_blank" rel="noopener noreferrer"
                           href={process.env.REACT_APP_API_BASE_URL + '/api/v1/pdf/photos'}>Fotók letöltése</a>
                    </IonButton>

                    <IonButton>
                        <a target="_blank" rel="noopener noreferrer"
                           href={process.env.REACT_APP_API_BASE_URL + '/api/v1/pdf/statement'}>Gyógyszerkezelési
                            nyilatkozat letöltése</a>
                    </IonButton>
                </div>
            </div>
        );
    };

    return (
        <IonPage>
            <IonContent>
                <Header/>

                <div className="ion-padding">
                    {showTemplate()}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(Pdf, (prevProps, nextProps) => prevProps.match.params.chartHash === nextProps.match.params.chartHash);
