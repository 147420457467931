import {FormDataInterface, FormInterface} from "../../../declarations";
import {defaultFormConfig} from "../helper/helper";

const login: FormDataInterface = {
    username: {
        label: 'Felhasználónév / pecsétszám',
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        validation: {
            required: true
        },
        ...defaultFormConfig
    },
    password: {
        label: 'Jelszó',
        elementType: 'input',
        elementConfig: {
            type: 'password'
        },
        validation: {
            required: true
        },
        ...defaultFormConfig
    }
};

const forms: FormInterface = {
    form_name: "Bejelentkezési űrlap",
    form_data: login
};

export default forms;
