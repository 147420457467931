import React from 'react';
import {
    IonButton,
    IonCheckbox,
    IonDatetime, IonIcon,
    IonInput,
    IonItem,
    IonLabel, IonRadio, IonRadioGroup,
    IonSelect,
    IonSelectOption,
    IonTextarea
} from "@ionic/react";
import {calendar} from "ionicons/icons";

import Signature from "../Signature/Signature";
import Photo from "../Photo/Photo";
import {ButtonHandlerInterface, FormConfigInterface} from "../../../store/declarations";
import classes from './Input.module.scss';
import Translate from "../Translate/Translate";
import {DATE_FORMAT} from "../../../utils/consants/common";

interface PropsInterface extends FormConfigInterface {
    photo?: any;
    takePhoto?: any;
    inputName?: string;
    buttonHandlers?: ButtonHandlerInterface[];
}

const input: React.FC<PropsInterface> = (props) => {
    const {
        inputName,
        takePhoto,
        value,
        base64Data,
        saved,
        elementType,
        elementConfig,
        changed,
        helper,
        label,
        multiple,
        touched,
        valid,
        validation,
        text,
        buttonHandlers,
        translated,
    } = props;
    let inputElement = null;
    let position: "floating" | "fixed" | "stacked" | undefined = "stacked";

    const inputClasses: any = [];
    const inputItemClasses = [classes['input-item']];

    if (!valid && validation.required && touched) {
        inputClasses.push(classes.invalid);
    }

    if (saved) {
        inputItemClasses.push(classes['input-item--disabled']);
    }

    switch (elementType) {
        case ('text'):
            if (text) {
                inputElement = <div dangerouslySetInnerHTML={{__html: text}}/>;
            }
            break;
        case ('button'):
            let buttonHandler = () => {
            };

            if (buttonHandlers) {
                const foundHandler = buttonHandlers.find((handler) => {
                    return handler.inputName === inputName;
                });

                if (foundHandler) {
                    buttonHandler = foundHandler.handler;
                }
            }

            inputElement = (
                <IonButton onClick={buttonHandler}>
                    {text}
                </IonButton>
            );
            break;
        case ('input'):
            inputElement = (
                translated ? <>
                    <IonInput
                        {...elementConfig}
                        value={value}
                        disabled={saved}
                        onIonChange={changed}
                        {...props}
                    />
                    <Translate textToTranslate={value}/>
                </> : <IonInput
                    {...elementConfig}
                    value={value}
                    disabled={saved}
                    onIonChange={changed}
                    {...props}
                />
            );
            break;
        case ('checkbox'):
            position = undefined;
            const isChecked = !!value;
            inputElement = (
                <IonCheckbox
                    {...elementConfig}
                    disabled={saved}
                    value={value}
                    checked={isChecked}
                    onIonChange={changed}
                    slot="start"
                    {...props}
                />
            );
            break;
        case ('radio'):
            inputElement = (
                elementConfig && elementConfig.options ? elementConfig.options.map((option: any) => (
                    <IonItem key={option.optionValue}>
                        <IonLabel>{option.displayName}</IonLabel>
                        <IonRadio
                            slot="start"
                            value={option.optionValue}
                            disabled={saved}
                        />
                    </IonItem>
                )) : null
            );
            break;
        case ('date'):
            position = undefined;
            const d = new Date();
            const dateString = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
            inputElement = (
                <>
                    <IonIcon icon={calendar} slot="start"/>
                    <IonDatetime
                        {...elementConfig}
                        displayFormat={DATE_FORMAT}
                        value={value}
                        onIonChange={changed}
                        cancelText="Mégse"
                        doneText="OK"
                        slot="start"
                        disabled={saved}
                        max={dateString}
                        {...props}
                    />
                </>
            );
            break;
        case ('textarea'):
            inputElement = (
                translated ? <>
                    <IonTextarea
                        {...elementConfig}
                        value={value}
                        onIonChange={changed}
                        disabled={saved}
                        {...props}
                    />
                    <Translate textToTranslate={value}/>
                </> : <IonTextarea
                    {...elementConfig}
                    value={value}
                    onIonChange={changed}
                    disabled={saved}
                    {...props}
                />
            );
            break;
        case ('signature'):
            inputElement = (
                <Signature
                    changed={changed}
                    value={value}
                    saved={saved}
                    {...props}
                />
            );
            break;
        case ('select'):
            if (elementConfig) {
                inputElement = (
                    <IonSelect
                        value={value}
                        onIonChange={changed}
                        multiple={multiple}
                        cancelText="Mégse"
                        okText="OK"
                        disabled={saved}
                        {...props}
                    >
                        {elementConfig && elementConfig.options ? elementConfig.options.map((option: any) => (
                            <IonSelectOption key={option.optionValue} value={option.value}>
                                {option.displayName}
                            </IonSelectOption>
                        )) : null}
                    </IonSelect>
                );
            }
            break;
        case ('picture'):
            position = undefined;
            inputElement = (
                <Photo
                    saved={saved}
                    base64Data={base64Data ?? ''}
                    elementType={elementType}
                    takePhoto={takePhoto}
                    inputName={inputName}
                    {...props}
                />
            );
            break;
        default:
            inputElement = (
                <IonInput
                    {...elementConfig}
                    value={value}
                    onIonChange={changed}
                    {...props}
                />
            );
    }

    const helperText = helper && (<div><small dangerouslySetInnerHTML={{__html: helper}}/></div>);

    return (
        <div className={inputItemClasses.join(' ')}>
            {elementType === 'radio' ?
                <IonRadioGroup value={value} onIonChange={changed}>
                    <IonLabel className={inputClasses.join(' ')}>{label}{helper}</IonLabel>
                    {inputElement}
                </IonRadioGroup>
                : (<>
                    {text && elementType === 'checkbox' ? <IonLabel className={classes['text-label']}>{text}</IonLabel> : ''}
                    <IonItem className={inputClasses.join(' ')}>
                        {label &&
                        <IonLabel className={classes.Label} position={position}>{label}{helperText}</IonLabel>}
                        {inputElement}
                    </IonItem>
                </>)
            }
        </div>
    );
};

export default React.memo(input, (prevProps, nextProps) => prevProps.value === nextProps.value);
