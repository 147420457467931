import {EventInterface, FormInterface, FormOptionsInterface, InputFieldInterface} from "../../store/declarations";
import {savePicture} from "./photo";
import {keys} from "./common";
import {CameraPhoto} from "@capacitor/core";

export const getFieldValue = (fieldName: string, fields: InputFieldInterface[]) => {
    const fieldValue = fields.find((inputField) => {
        return inputField.name === fieldName;
    });

    return fieldValue ? fieldValue.value : '';
};

export const initFormValues = async (form: FormInterface, formValues: InputFieldInterface[], event: EventInterface | null = null, fileSystem?: any) => {
    uploadEventSpecificFields(form, event);

    for (let formValue of formValues) {
        const {name, value} = formValue;

        if (form.form_data.hasOwnProperty(name)) {
            form.form_data[name].value = value;
        }
    }

    for (let inputName of keys(form.form_data)) {
        form.form_data[inputName].valid = setValidity(form.form_data[inputName].value, form.form_data[inputName].validation);
    }

};

const uploadEventSpecificFields = (form: FormInterface, event: EventInterface | null) => {
    if (event !== null) {
        const fieldsToSet: any = {};
        fieldsToSet['admission_to'] = event.hospitals;
        fieldsToSet['sports'] = event.sports;
        fieldsToSet['accreditation_type'] = event.accreditation;

        for (let inputName of keys(form.form_data)) {
            if (Object.keys(fieldsToSet).indexOf(inputName + '') > -1) {
                let newOptions: FormOptionsInterface[] = [];
                const fields = typeof fieldsToSet[inputName] === 'string' ? fieldsToSet[inputName].trim().split(',') : fieldsToSet[inputName];

                newOptions = fields.map((option: any) => {
                    const name = typeof option === 'string' ? option : option.name;
                    const value = typeof option === 'string' ? option : option.email;

                    return setOptions(name, value);
                });

                let oldOptions = [...form.form_data[inputName].elementConfig.options ?? []];
                oldOptions = oldOptions.concat(newOptions);
                form.form_data[inputName].elementConfig.options = oldOptions;
            }
        }
    }
};

export const setOptions = (name: string, value: string) => {
    return {
        displayName: name,
        optionValue: value
    }
};

export function setValidity(value: any, rules: any) {
    let isValid = true;

    if (!rules) {
        return true;
    }

    if (rules.required && typeof value == "string") {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.required && typeof value == "object") {
        isValid = value.length > 0 && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
        isValid = isEmail(value) && isValid;
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }

    return isValid;
}

export const isEmail = (value: string) => {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return pattern.test(value);
};

export const setFormArray = (form: FormInterface) => {
    const formElementsArray: any[] = [];

    if (form && form.form_data) {
        for (let key of keys(form.form_data)) {
            formElementsArray.push({
                id: key,
                config: form.form_data[key]
            });
        }
    }

    return formElementsArray;
};

export const inputChangedHandler = (value: any, inputIdentifier: number, form: FormInterface, updateForm: any, updateFormIsValid?: any) => {
    const updatedForm: FormInterface = {...form};
    const updatedFormElement = {...updatedForm.form_data[inputIdentifier]};

    if (updatedFormElement.elementType === 'checkbox') {
        updatedFormElement.value = updatedFormElement.value === '' ? '1' : '';
    } else {
        updatedFormElement.value = value;
    }

    updatedFormElement.touched = true;
    updatedFormElement.valid = setValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedForm.form_data[inputIdentifier] = updatedFormElement;

    if (updateFormIsValid) {
        setFormValidity(updatedForm, updateFormIsValid);
    }

    updateForm(updatedForm);
};


export const setFormValidity = (form: FormInterface, updateFormIsValid: any = null) => {
    let formIsValid = true;

    for (let inputIdentifier in form.form_data) {
        if (form.form_data.hasOwnProperty(inputIdentifier)) {
            formIsValid = form.form_data[inputIdentifier].valid && formIsValid;
        }
    }

    if (updateFormIsValid) {
        updateFormIsValid(formIsValid);
    }
};

export const saveBase64Image = async (form: FormInterface, formValue: any, fileSystem: any) => {
    const {value} = formValue;
    const photo: CameraPhoto = {
        webPath: value,
        format: 'jpeg'
    };
    return await savePicture(photo, fileSystem.writeFile);
};
