import axios from '../../axios';
import * as actionTypes from "./actionTypes";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import * as actions from "./index";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";
import {getSyncCharts, loadPhotosFromFilesystem} from "../../utils/functions/chart";
import {ChartInterface} from "../declarations";
import _ from "lodash";
import {isNoNeedToSync, setSyncDate} from "../../utils/functions/sync";

const AUTO_SYNC_COUNTER_BY_SECOND = 5 * 60;
let autoSync: any;

export const syncStart = () => {
    console.log('[actions/sync.ts] syncStart');
    return {
        type: actionTypes.SYNC_START
    };
};

export const syncEnd = () => {
    console.log('[actions/sync.ts] syncEnd');
    return {
        type: actionTypes.SYNC_END
    };
};

export const updateLastSyncDate = (lastSyncDate: any) => {
    console.log('[actions/sync.ts] updateLastSyncDate');
    return {
        type: actionTypes.SYNC_SUCCESS,
        lastSyncDate: lastSyncDate
    };
};

export const syncFail = () => {
    return {
        type: actionTypes.SYNC_FAIL
    };
};

export const uploadChartsToServer = (lastSyncDate: number, isNeedEndSyncProcess: boolean = true, readFile: any) => {
    return async (dispatch: any, getState: any) => {
        if (!readFile) {
            throw new Error('Use Filesystem readFile function missing');
        }

        dispatch(syncStart());

        const syncCharts: ChartInterface[] = getSyncCharts(getState);
        const config = getAxiosConfigWithToken(getState().auth.token);
        console.log('[actions/sync.ts] syncCharts', syncCharts);

        if (isNoNeedToSync(syncCharts)) {
            return new Promise((resolve: any) => {
                console.log('[actions/sync.ts] sync end');
                if (isNeedEndSyncProcess) {
                    dispatch(syncEnd());
                }
                resolve();
            });
        }

        const syncChartsToSend = _.cloneDeep(syncCharts);
        await loadPhotosFromFilesystem(syncChartsToSend, readFile);
        setSyncDate(syncChartsToSend, lastSyncDate);
        console.log('[actions/sync.ts] syncChartsToSend', syncChartsToSend);

        return new Promise((resolve: any, reject: any) => {
            axios.post(apiEndpoints.API_SYNC_SAVE, {charts: syncChartsToSend}, config)
                .then(response => {
                    if (response.data.success !== true) {
                        console.error('[actions/sync.ts] API_SYNC_SAVE', response);
                        dispatch(syncFail());
                        dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                        reject();

                        throw new Error(getErrorMessageFromResponse(response));
                    }

                    if (isNeedEndSyncProcess) {
                        dispatch(syncEnd());
                    }

                    console.log('[actions/sync.ts] synSuccess');
                    dispatch(actions.hideSyncAlert());
                    resolve(dispatch(actions.updateChartsSyncDate(syncCharts, lastSyncDate)));
                })
                .catch((e) => {
                    console.error('[sync.ts] API_SYNC_SAVE', e);
                    dispatch(syncEnd());
                    dispatch(syncFail());
                    dispatch(actions.showSyncAlert());
                    dispatch(actions.showToast('Szinkronizáció sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'));
                    reject();
                });
        });

    };
};

export const startAutoSync = (readFile: any) => {
    return (dispatch: any) => {
        autoSync = setTimeout(() => {
            const lastSyncDate = new Date().getTime();
            dispatch(uploadChartsToServer(lastSyncDate, true, readFile));
            dispatch(startAutoSync(readFile));
        }, AUTO_SYNC_COUNTER_BY_SECOND * 1000);
    }
};

export const stopAutoSync = () => {
    return () => {
        clearTimeout(autoSync);
    }
};

export const startTwoWaySync = (readFile: any) => {
    return (dispatch: any) => {
        const lastSyncDate = new Date().getTime();
        const isNeedEndSyncProcess = false;

        dispatch(uploadChartsToServer(lastSyncDate, isNeedEndSyncProcess, readFile)).then(async () => {
            console.log('[actions/sync.ts] uploadChartsToServer end');
            await dispatch(actions.getChartsFromServer());
            console.log('[actions/sync.ts] getChartsFromServer end');
            dispatch(actions.updateLastSyncDate(lastSyncDate));
            dispatch(actions.showToast('A szinkronizáció sikeres volt.'));
            dispatch(syncEnd());
        }).catch(() => {
            dispatch(syncEnd());
        });
    }
};

export const getSyncDataFromServer = (token: string) => {
    return (dispatch: any) => {
        const config = getAxiosConfigWithToken(token);
        console.log('[actions/sync.ts] getSyncDataFromServer');

        return new Promise(resolve => {
            axios
                .post(apiEndpoints.API_SYNC_GET, null, config)
                .then(async (response: any) => {
                    if (response.data.success !== true) {
                        console.error('[actions/sync.ts] API_SYNC_GET', response);
                        dispatch(actions.logout());
                        dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                        return;
                    }

                    await dispatch(actions.updateUser(response.data.payload.user));
                    await dispatch(actions.updateEvent(response.data.payload.event));
                    console.log('[actions/sync.ts] getsync done');
                    resolve(response.data.payload);
                })
                .catch(err => {
                    dispatch(actions.showToast('Az adatok lekérése sikertelen volt. Lehet, hogy nincs internetkapcsolat. Kérem próbálja meg újra.'));
                });
        });
    };
};
