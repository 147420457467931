import {FormDataInterface} from "../../../../declarations";
import {defaultFormConfig} from "../../helper/helper";

const declaration: FormDataInterface = {
    text_declaration_top: {
        label: '',
        elementType: 'text',
        text: 'A jelen biztosítotti nyilatkozat a fentiekben megjelölt szerződő (a továbbiakban: ' +
            'szerződő) és a Generali Biztosító Zrt. (a továbbiakban: biztosító) ' +
            'között létrejött Egészségbiztosítási szerződés (a továbbiakban: ' +
            'szerződés) részét képezi. A szerződés részét képező kapcsolódó dokumentumok ' +
            'letölthetők a http://www.semmelweis.hu webhelyről.',
        elementConfig: {},
        validation: {
            required: false,
        },
        ...defaultFormConfig
    },
    declaration_1: {
        label: 'Kérem a fentiekben megjelölt szerződő és a biztosító között ' +
            'létrejött Egészségbiztosítási szerződés hatályának reám, mint ' +
            'biztosítottra való kiterjesztését.',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    declaration_2: {
        label: 'Alulírott biztosított jelen nyilatkozat aláírásával hozzájárulok ahhoz, ' +
            'hogy a szerződő a biztosítotti jogviszony létrejötte, nyilvántartása ' +
            'és a szolgáltatás teljesítése céljából indokolt, fentiekben megadott ' +
            'adataimat a biztosítónak, mint adatfeldolgozónak a cél és ok megjelölésével ' +
            'átadja, és azokat nyilvántartsa.',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    declaration_3: {
        label: 'Alulírott biztosított jelen nyilatkozat aláírásával elismerem, hogy a ' +
            'szerződő és a biztosító között létrejött egészségbiztosítási szerződés ' +
            'mellékletét képező „Az ügyféltájékoztató és a biztosítási szerződésre ' +
            'vonatkozó általános rendelkezések” című általános szerződési ' +
            'feltételt, valamint a „Terméktájékoztató a Generali Biztosító Zrt. ' +
            'szolgáltatásfinanszírozó egészségbiztosításról” elnevezésű dokumentumot ' +
            'megismertem, az azokban foglaltakat megértettem és tudomásul ' +
            'vettem, különös tekintettel az egészségügyi szolgáltatások ' +
            'körére, tartalmára, az éves szolgáltatási limit és részlimit, továbbá ' +
            'az önrészek és a kizárások/mentesülések alkalmazására vonatkozó ' +
            'feltételeket.',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    declaration_4: {
        label: 'Hozzájárulok ahhoz, hogy a Generali Biztosító Zrt. az általam bejelentett ' +
            'szolgáltatási igényről, annak típusáról, a szolgáltatás teljesítéséről ' +
            'vagy az elutasítás tényéről, illetve a szolgáltatási igény ' +
            'teljesítése esetén a kifizetett szolgáltatási összegről a szerződőt ' +
            '– a szerződő ezirányú kérése esetén – tájékoztassa.',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    text_declaration: {
        label: '',
        elementType: 'text',
        text: 'A biztosító és a biztosított kapcsolattartása valamint a biztosítottak tájékoztatása' +
            'magyar és angol nyelven történik azzal, hogy véleménykülönbség' +
            'esetén a magyar nyelvű szöveg az irányadó.',
        elementConfig: {},
        validation: {
            required: false,
        },
        ...defaultFormConfig
    }
};

export default declaration;
