import {defaultFormConfig} from "../../helper/helper";
import {FormDataInterface} from "../../../../declarations";

const privacy: FormDataInterface = {
    privacy_1: {
        label: 'Alulírott kifejezett hozzájárulásomat adom és egyben felhatalmazom' +
            'a Generali Biztosító Zrt-t, hogy az egészségi állapotomra' +
            'vonatkozó, a biztosítotti jogviszonyom (biztosítási szerződésem)' +
            'létrejöttéhez, módosításához, állományban tartásához, a biztosítási' +
            'szerződésből származó követelések megítéléséhez, illetve a' +
            'szolgáltatási igények elbírálásához szükséges személyes adataimat' +
            'beszerezze és kezelje.' +
            'Hozzájárulok továbbá, hogy a biztosító a fent megjelölt biztosítási' +
            'szerződés vonatkozásában is felhasználja a fenti célokból az akár a' +
            'későbbiekben, akár korábban bármikor megadott illetve a birtokába' +
            'került (a biztosító rendelkezésére álló) egészségügyi adatokat.',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    privacy_2: {
        label: 'Egyúttal felmentem az ezen adatokat jogszabályi felhatalmazás' +
            'alapján nyilvántartó intézményeket, személyeket (pl. háziorvos,' +
            'járó- és fekvőbeteg, valamint egyéb egészségügyi ellátó és szociális' +
            'intézmények) és szervezeteket (pl. társadalombiztosítási szerv,' +
            'a mindenkor hatályos magyar jogszabályokban meghatározott, az' +
            'egészségkárosodás/egészségi állapot mértékének megállapítására' +
            'jogosult szerv) a titoktartási kötelezettségük alól.',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    privacy_4: {
        label: 'Alulírott kifejezett hozzájárulásomat adom ahhoz, hogy a biztosító a' +
            'biztosítási szerződéssel kapcsolatos – kockázatvállalással, a követelések' +
            'megítélésével, kárrendezéssel, együttbiztosítással, viszontbiztosítással' +
            'összefüggő – az egészségi állapotomra vonatkozó' +
            'adatokat a biztosító az anyavállalatának, tagállami viszontbiztosítónak,' +
            'együttbiztosítás esetén tagállami kockázatvállaló biztosítónak' +
            '–, illetve ellátásszervezői tevékenység esetén annak lefolytatása' +
            'céljából, a biztosítási titokra vonatkozó rendelkezések betartása' +
            'mellett a kijelölt egészségügyi szolgáltatónak és/vagy a szolgáltatásszervezőnek,' +
            'valamint a szolgáltatásszervező egészségügyi' +
            'szolgáltatást nyújtó szerződött partnereinek továbbítsa.',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    text_privacy: {
        label: 'Semmelweis Egészségügyi Kft.',
        elementType: 'text',
        text: 'Székhelye: 1085 Budapest, Üllői út 26.<br>' +
            'Cégjegyzékszám: 01-09-879749',
        elementConfig: {},
        validation: {
            required: false
        },
        ...defaultFormConfig
    },
    text_privacy_2: {
        label: 'Europe Assistance Magyarország Kft.',
        elementType: 'text',
        text: 'Székhely: 1134 Budapest, Dévai utca 26<br>' +
            'Cégjegyzékszám: 01-10-043228',
        elementConfig: {},
        validation: {
            required: false
        },
        ...defaultFormConfig
    },
    text_privacy_3: {
        label: '',
        elementType: 'text',
        text: 'Az egészségi állapotra vonatkozó adatokat és a biztosítási titkot' +
            'képező személyes adatokat a biztosító az adatvédelmi előírások' +
            'betartása mellett adatfeldolgozó részére-, továbbá kiszervezési' +
            'tevékenység keretében szerződött szakértői (orvosok, orvosszakértők,' +
            'egészségügyi szolgáltatók) részére szolgáltatási igény rendezésével' +
            'kapcsolatos szakértői vélemény elkészítése érdekében' +
            'átadhatja.',
        elementConfig: {},
        validation: {
            required: false
        },
        ...defaultFormConfig
    },
    text_privacy_4: {
        label: '',
        elementType: 'text',
        text: 'A biztosítási szerződéssel kapcsolatos adatkezelésekről, az igénybe' +
            'vett adatfeldolgozókról, a személyes adatok kezelésével kapcsolatos' +
            'jogokról és jogorvoslati lehetőségekről részletes tájékoztatást a biztosító' +
            'adatkezelési tájékoztatójában talál. Az adatkezelési tájékoztató' +
            'elérhető a generali.hu oldalról.',
        elementConfig: {},
        validation: {
            required: false
        },
        ...defaultFormConfig
    }
};

export default privacy;
