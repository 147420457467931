import React, {useCallback, useEffect, useState} from 'react';
import {IonContent, IonPage} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";

import Input from "../../../components/UI/Input/Input";
import {setFormArray} from "../../../utils/functions/form";
import form from "../../../store/static/forms/profileForm/profileForm"
import * as actions from "../../../store/actions";
import ControlButtons from "../../../components/UI/ControlButtons/ControlButtons";
import _ from "lodash";

const UserEdit: React.FC = () => {
    const userStore = useSelector((state: any) => state.user);

    const dispatch = useDispatch();
    const editUser = (editedProfile: any) => dispatch(actions.editUser(editedProfile));
    const showSuccessAlert = (message: string) => dispatch(actions.showSuccessAlert(message));

    const [activeForm, updateActiveForm] = useState(Array);
    const [user, updateUser] = useState();

    let editedProfile: { [key: string]: any } = {};
    let history = useHistory();

    const init = useCallback(() => {
        const userClone = _.cloneDeep(userStore);
        const formElementsArray = initForm(form, userClone);

        updateUser(userClone);
        updateActiveForm(formElementsArray);
    }, [updateActiveForm, userStore]);

    useEffect(() => {
        if (userStore.name) {
            init();
        }
    }, [userStore, init]);

    const initForm = (form: any, user: any) => {
        /* Fill with values */
        Object.keys(user).forEach((key) => {
            if (form.form_data[key] !== undefined) {
                form.form_data[key].value = user[key]
            }
        });

        /* Return form in array */
        return setFormArray(form);
    };

    const inputChangedHandler = (event: any, inputId: any) => {
        const updatedProperty: { [index: string]: any } = {};
        updatedProperty[inputId] = event.detail.value;
        editedProfile = {
            ...editedProfile,
            ...updatedProperty
        }
    };

    const onSubmitHandler = () => {
        const updatedProfile = {
            ...user,
            ...editedProfile
        };

        if (editUser(updatedProfile)) {
            showSuccessAlert('A profil sikeresen módosult.')
            history.push("/charts");
        }
    };

    const onCancelHandler = () => {
        history.goBack();
    };

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>User</h1>
                <form className="clearfix">
                    {
                        activeForm.map((formElement: any) => (
                            <Input
                                disabled={!user.editable}
                                key={formElement.id}
                                inputName={formElement.id}
                                {...formElement.config}
                                changed={(event: any) => inputChangedHandler(event, formElement.id)}
                            />
                        ))
                    }

                    <ControlButtons
                        onCancelHandler={onCancelHandler}
                        onSaveHandler={onSubmitHandler}
                        formIsValid={true}
                        saveButtonText={'Mentés'}
                    />
                </form>
            </IonContent>
        </IonPage>
    );
};

export default UserEdit;
