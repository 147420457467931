import {
    ChartFormInterface,
    ChartInterface,
    EmptyChartParamsInterface,
    FormInterface,
    InputFieldInterface
} from "../../store/declarations";
import {PERSONAL_FORM} from "./constants";
import {ChartEditRouteParams} from "../../store/declarations/route";
import {loadSavedPhoto} from "./photo";
import {isBase64Image} from "./common";

export const getChartByHash = (charts: ChartInterface[], chartHash: any) => {
    if (!charts) {
        return null;
    }

    return charts.find((chart: ChartInterface) => {
        return chart.hash === chartHash;
    }) ?? null;
};

export const getFirstChartFormByType = (chart: ChartInterface, formType: string): ChartFormInterface | null => {
    if (!chart || !chart.forms) {
        return null;
    }

    return chart.forms.filter((form) => {
        return form.type === formType;
    })[0] ?? null;
};

export const getAllChartFormByType = (chart: ChartInterface, formType: string): ChartFormInterface[] => {
    if (!chart || chart.forms.length === 0) {
        return [];
    }

    return chart.forms.filter((form) => form.type === formType);
};

export const getPersonalFormHash = (charts: ChartInterface[], chartHash: string): number | any => {
    const chart = getChartByHash(charts, chartHash);
    let formHash = null;

    if (chart) {
        chart.forms.forEach((form) => {
            if (form.type === PERSONAL_FORM) {
                formHash = form.hash;
            }
        })
    }

    return formHash;
}

export const getChartFormById = (chart: ChartInterface, formHash: any): ChartFormInterface | null => {
    return chart.forms.filter((form) => form.hash === formHash)[0] ?? null;
};

export const navigationToChardEditPage = (history: any, routeParams: ChartEditRouteParams) => history.push(`/charts/${routeParams.chartHash}/${routeParams.formType}/${routeParams.formHash}/${routeParams.formPageIndex}`);

export const isExistChartsInState = (getState: any) => getState().chart.charts !== null && getState().chart.charts !== undefined;

export const initEmptyChart = (emptyChartParams: EmptyChartParamsInterface): ChartInterface => {
    const {eventId, chartHash, userId, formHash} = emptyChartParams;
    return {
        event_id: eventId,
        hash: chartHash,
        send_mail_to: '',
        last_sync_date: 0,
        created_date: new Date().getTime(),
        forms: [initEmptyForm(PERSONAL_FORM, userId, formHash)]
    }
};

export const initEmptyForm = (formType: string, userId: number, formHash: string): ChartFormInterface => {
    return {
        hash: formHash,
        input_fields: [],
        type: formType,
        created_date: new Date().getTime(),
        user_id: userId,
        valid: 0
    }
}

export const fillUpChartFormFields = (chartForm: ChartFormInterface, form: FormInterface) => {
    for (let inputIdentifier in form.form_data) {
        if (form.form_data.hasOwnProperty(inputIdentifier)) {
            const value = form.form_data[inputIdentifier].value;
            let foundField = false;

            if (chartForm.input_fields) {
                chartForm.input_fields.forEach((field: InputFieldInterface) => {
                    if (field.name === inputIdentifier) {
                        foundField = true;
                        field.value = value;
                        field.saved = true;
                    }
                });
            }

            if (!foundField) {
                chartForm.input_fields.push({
                    name: inputIdentifier,
                    value: value,
                    saved: true
                });
            }
        }
    }
};

export const getSyncCharts = (getState: any): ChartInterface[] => {
    if (isStateEmptyWithCharts(getState)) {
        return [];
    }

    return getState().chart.charts
        .filter((chart: ChartInterface) => chart.event_id === getState().event.id)
        .filter((chart: ChartInterface) => chart.last_sync_date === 0);
};

export const isFormsValid = (chart: ChartInterface) => {
    for (let form of chart.forms) {
        if (form.valid === 0) {
            return false;
        }
    }

    return true;
};

export const isStateEmptyWithCharts = (getState: any) => typeof getState().chart === "undefined" && typeof getState().chart.charts === "undefined";

export const loadPhotosFromFilesystem = async (charts: ChartInterface[], readFile: any) => {
    for (const chart of charts) {
        for (let form of chart.forms) {
            for (let field of form.input_fields) {
                if (['accreditation_front', 'accreditation_back', 'personal'].indexOf(field.name) > -1 && field.value) {
                    if (!isBase64Image(field.value)) {
                        field.value = await loadSavedPhoto(field.value, readFile);
                    }
                }
            }
        }
    }
}

export const setCharInputFieldsToSaved = (charts: ChartInterface[]) => {
    for (let chart of charts) {
        for (let form of chart.forms) {
            for (let field of form.input_fields) {
                field.saved = true;
            }
        }
    }
};
