import React from "react";
import classes from "./Header.module.scss";
import {IonRow, IonCol} from "@ionic/react";
import Icon from "../Icon/Icon";
import Image from "../Image/Image";
import {useSelector} from "react-redux";
import Moment from "react-moment";
import {DATE_FORMAT} from "../../utils/consants/common";

const Header = () => {
    const user = useSelector((state: any) => state.user);
    const event = useSelector((state: any) => state.event);

    return (
        <IonRow className={classes["header"]}>
            <IonCol className={classes["header__left-side"]}>
                <Icon/>
                <IonRow>
                    <h2 className={classes["header__doctor-name"]}>{user.name}</h2>
                </IonRow>
                <IonRow>
                    <p className={classes["header__seal-number"]}>{user.seal_number}</p>
                </IonRow>
            </IonCol>

            <IonCol>
                <Image src={event.logo} alt="esemeny logo"/>
                <div className={classes["header__event-details"]}>
                    <h2>{event.name}</h2>
                    <p>{event.city}</p>
                    <p>{event.address}</p>
                    <p><Moment date={new Date(event.start)} format={DATE_FORMAT}/> - <Moment date={new Date(event.end)} format={DATE_FORMAT}/></p>
                </div>
            </IonCol>
        </IonRow>
    );
};

export default Header;
