import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IonPage, IonContent, IonRow, IonItem, IonButton, useIonViewWillEnter, IonNote} from "@ionic/react";
import {Redirect} from "react-router";
import {RouteComponentProps} from "react-router-dom";

import loginForm from "../../store/static/forms/login/login";
import * as actions from "../../store/actions/index";
import logo from "../../assets/images/logo.png";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/UI/Input/Input";
import classes from "./Login.module.scss";
import {inputChangedHandler, setFormArray, setFormValidity} from "../../utils/functions/form";
import _ from "lodash";

const Login: React.FC<RouteComponentProps> = () => {
    const isAuthenticated = useSelector((state: any) => state.auth.token !== null);
    const authError = useSelector((state: any) => state.auth.error);
    const authRedirectPath = useSelector((state: any) => state.auth.authRedirectPath);

    const dispatch = useDispatch();
    const doAuth = (username: string, password: string) => dispatch(actions.doAuth(username, password));

    const [activeForm, updateActiveForm] = useState();
    const [authRedirect, updateAuthRedirect] = useState();

    const formElementsArray: any[] = setFormArray(activeForm);

    useIonViewWillEnter(() => {
        updateActiveForm(_.cloneDeep(loginForm));
    });

    const submitHandler = () => {
        doAuth(activeForm.form_data.username.value, activeForm.form_data.password.value);
    };

    useEffect(() => {
        if (isAuthenticated) {
            updateAuthRedirect(<Redirect to={authRedirectPath}/>);
        }
    }, [isAuthenticated, updateAuthRedirect, authRedirectPath]);

    useEffect(() => {
        if (activeForm) {
            setFormValidity(activeForm);
        }
    }, [activeForm]);

    const inputHandler = (event: any, inputIdentifier: number) => {
        inputChangedHandler(event.target.value, inputIdentifier, activeForm, updateActiveForm);
    };

    return (
        <IonPage>
            <IonContent className={classes["login"]}>
                {authRedirect}
                <img width="290" className={classes["login__logo"]} src={logo} alt="avatar"/>
                <h1 className={classes["login__title"]}>Insurance Chart</h1>

                <form>
                    <div className={classes["login__input"]}>
                        {formElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                inputName={formElement.id}
                                {...formElement.config}
                                changed={(event: any) => inputHandler(event, formElement.id)}
                            />
                        ))}
                    </div>

                    {authError && <IonNote color="danger">Bejelentkezési hiba!</IonNote>}

                    <IonRow className={[classes["login__input"], classes["login__input--margin-top"]].join(' ')}>
                        <IonItem lines="none">
                            <IonButton
                                expand="full"
                                size="large"
                                color="success"
                                shape="round"
                                onClick={submitHandler}
                            >
                                Belépés
                            </IonButton>
                        </IonItem>
                    </IonRow>
                </form>

                <Footer/>
            </IonContent>
        </IonPage>
    );
};


export default Login;
