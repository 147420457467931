import {FormInterface} from "../../../declarations";
import {defaultFormConfig} from "../helper/helper";

const emailForm: FormInterface = {
    form_name: 'Email form',
    form_data: {
        personal_email: {
            label: 'Diák email címe',
            elementType: 'input',
            elementConfig: {
                type: 'text'
            },
            validation: {
                required: false,
                editable: true
            },
            ...defaultFormConfig
        },
        custom_email: {
            label: 'Egyéb email címek',
            helper: 'Sorolja fel az email címeket vesszővel elválasztva. Pl.: email1@email.com, email2@email.com',
            elementType: 'input',
            elementConfig: {
                type: 'text'
            },
            validation: {
                required: false,
                editable: true
            },
            ...defaultFormConfig
        },
    }
};

export default emailForm;
