import React from "react";
import {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonToolbar,
    IonRow,
    IonIcon
} from "@ionic/react";
import classes from "./Menu.module.scss";
import {personOutline, powerOutline, caretDownOutline, documentText, sync} from "ionicons/icons";
import {Link, RouteComponentProps} from "react-router-dom";
import {menuController} from "@ionic/core";
import {useDispatch} from "react-redux";
import * as actions from "../../store/actions/index";

const Menu: React.FC<RouteComponentProps> = ({location}) => {
    const dispatch = useDispatch();
    const logout = () => dispatch(actions.logout());

    const closeMenu = () => {
        menuController.close();
    };

    return (
        <IonMenu
            side="start"
            menuId="first"
            content-id="main-content"
            className={classes["menu"]}
        >
            <IonHeader className={classes["menu__header"]}>
                <IonToolbar className={classes["menu__toolbar"]} color="primary">
                    <IonRow>
                        <img
                            className={classes["menu__avatar"]}
                            src={"./assets/photo@2x.png"}
                            alt="avatar"
                        />
                    </IonRow>
                    <IonRow className={classes["menu__title"]}>
                        Menu
                        <IonIcon
                            className={classes["menu__icon"]}
                            icon={caretDownOutline}
                        />
                    </IonRow>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList no-lines>
                    <IonItem lines="none">
                        <IonIcon
                            className={classes["menu__item-icon"]}
                            icon={documentText}
                        />
                        <Link
                            to={"/charts"}
                            className={classes["menu__item-text"]}
                            onClick={closeMenu}
                        >Charts</Link>
                    </IonItem>
                    <IonItem lines="none">
                        <IonIcon
                            className={classes["menu__item-icon"]}
                            icon={sync}
                        />
                        <Link
                            to={"/sync"}
                            className={classes["menu__item-text"]}
                            onClick={closeMenu}
                        >Szinkronizáció</Link>
                    </IonItem>
                    <IonItem lines="none">
                        <IonIcon
                            className={classes["menu__item-icon"]}
                            icon={personOutline}
                        />
                        <Link
                            to={"/user/edit"}
                            className={classes["menu__item-text"]}
                            onClick={closeMenu}
                        >Profil szerkesztése</Link>
                    </IonItem>
                    <IonItem lines="none">
                        <IonIcon
                            className={classes["menu__item-icon"]}
                            icon={powerOutline}
                        />
                        <span
                            className={classes["menu__item-text"]}
                            onClick={() => menuController.close().then(() => logout())}>Kijelentkezés</span>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
