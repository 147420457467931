import React from 'react';
import {
    IonCol, IonGrid, IonRow
} from '@ionic/react';
import {RouteComponentProps} from "react-router";

import Input from '../../components/UI/Input/Input';
import {setFormArray} from "../../utils/functions/form";
import {ButtonHandlerInterface, FormInterface} from "../../store/declarations";
import ControlButtons from "../UI/ControlButtons/ControlButtons";

interface PropsInterface extends RouteComponentProps {
    form: FormInterface;
    takePhoto?: any
    inputChangedHandler: any;
    formIsValid: any;
    onSaveHandler: any;
    onAlternateSaveHandler?: any;
    onCancelHandler: any;
    saveButtonText?: string;
    alternateSaveButtonText?: string;
    buttonHandlers?: ButtonHandlerInterface[];
}

const chartForm: React.FC<PropsInterface> = (props) => {
    const {
        form,
        inputChangedHandler,
        takePhoto,
        saveButtonText,
        alternateSaveButtonText,
        buttonHandlers,
        onCancelHandler,
        onSaveHandler,
        onAlternateSaveHandler
    } = props;

    const formElementsArray: any[] = setFormArray(form);

    return (
        <>
            {form &&
            <IonGrid className="ion-padding">
                <IonRow>
                    <IonCol size="12">
                        <div>
                            <h1>{form.form_name}</h1>
                            {form.info ? <h5><strong>{form.info}</strong></h5> : null}
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {formElementsArray.map(formElement => (
                        <IonCol
                            size={formElement.config.colSize || '12'}
                            key={formElement.id}
                        >
                            <Input
                                inputName={formElement.id}
                                {...formElement.config}
                                takePhoto={takePhoto}
                                changed={(event: any) => inputChangedHandler(event, formElement.id)}
                                buttonHandlers={buttonHandlers}
                            />
                        </IonCol>
                    ))}
                </IonRow>

                <IonRow>
                    <IonCol size="12">
                        <ControlButtons
                            onCancelHandler={onCancelHandler}
                            onSaveHandler={onSaveHandler}
                            onAlternateSaveHandler={onAlternateSaveHandler}
                            formIsValid={true}
                            saveButtonText={saveButtonText}
                            alternateSaveButtonText={alternateSaveButtonText}
                        />
                    </IonCol>
                </IonRow>
            </IonGrid>
            }
        </>
    );
};

export default chartForm;
