import {defaultFormConfig} from "../../helper/helper";
import {FormDataInterface} from "../../../../declarations";

const signatures: FormDataInterface = {
    signature_of_insurance: {
        label: 'Biztosított aláírása',
        elementType: 'signature',
        elementConfig: {},
        saved: false,
        validation: {
            required: true,
        },
        ...defaultFormConfig
    }
};

export default signatures;
