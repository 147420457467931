import React, {useCallback, useEffect, useState} from 'react';
import {RouteComponentProps} from "react-router";
import {IonContent, IonPage, useIonViewDidLeave} from "@ionic/react";
import axios from "../../../axios";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";

import Header from "../../../components/Header/Header";

import emailForm from "../../../store/static/forms/email/email";
import {API_MAIL} from "../../../utils/consants/apiEndpoints";
import * as actions from "../../../store/actions";
import {ChartInterface, EventInterface, FormInterface, InputFieldInterface} from "../../../store/declarations";
import Spinner from "../../../components/Spinner/Spinner";
import ChartForm from "../../../components/ChartForm/ChartForm";
import {getChartByHash} from "../../../utils/functions/chart";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../../utils/functions/http";
import {getFieldValue, inputChangedHandler, isEmail, initFormValues} from "../../../utils/functions/form";

interface EmailProps extends RouteComponentProps<{
    chartHash: string;
}> {
}

const Email: React.FC<EmailProps> = (props) => {
    const charts: ChartInterface[] = useSelector((state: any) => state.chart.charts);
    const isChartsLoading: boolean = useSelector((state: any) => state.chart.loading);
    const event: EventInterface = useSelector((state: any) => state.event);
    const token: string = useSelector((state: any) => state.auth.token);

    const dispatch = useDispatch();
    const showToast = (message: string) => dispatch(actions.showToast(message));
    const showSuccessAlert = (message: string) => dispatch(actions.showSuccessAlert(message));

    const {match} = props;
    const [activeForm, updateActiveForm] = useState();
    const [isCurrentFormLoaded, updateIsCurrentFormLoaded] = useState(false);
    const getCurrentChart = useCallback(() => getChartByHash(charts, match.params.chartHash), [charts, match]);

    const setValues = useCallback((chart: ChartInterface, newForm: FormInterface) => {
        let admissionToEmailAddress = '';
        let personalEmailAddress = '';

        if (chart.forms.length === 1) {
            personalEmailAddress = getFieldValue('email', chart.forms[0].input_fields);
            personalEmailAddress = setEmailValue(personalEmailAddress);
        }

        if (chart.forms.length > 1) {
            admissionToEmailAddress = getFieldValue('admission_to', chart.forms[chart.forms.length - 1].input_fields);
            admissionToEmailAddress = setEmailValue(admissionToEmailAddress);
        }

        const values: InputFieldInterface[] = [
            {
                name: 'admission_to_email',
                value: admissionToEmailAddress
            },
            {
                name: 'organizer_email',
                value: event.emails
            },
            {
                name: 'personal_email',
                value: personalEmailAddress
            }
        ];

        return initFormValues(newForm, values, event);
    }, [event]);

    const initForm = useCallback( async () => {
        const chart = getCurrentChart();

        if (chart !== null && emailForm) {
            const newForm = _.cloneDeep(emailForm);

            await setValues(chart, newForm);
            updateActiveForm(newForm);

            // Need timeout to avoid flashing staticForms
            setTimeout(() => {
                updateIsCurrentFormLoaded(true);
            }, 500);
        }
    }, [getCurrentChart, updateIsCurrentFormLoaded, setValues]);

    useEffect(() => {
        if (!isChartsLoading || !isCurrentFormLoaded) {
            initForm();
        }
    }, [isChartsLoading, initForm, isCurrentFormLoaded]);

    useIonViewDidLeave(() => {
        updateActiveForm(undefined);
    }, [updateActiveForm]);

    const onSaveHandler = () => {
        const emailAddresses = collectEmailAddresses();
        sendMail(emailAddresses.join(', '));
    };

    const collectEmailAddresses = () => {
        let addresses = [];

        for (let inputIdentifier in activeForm.form_data) {
            if (activeForm.form_data.hasOwnProperty(inputIdentifier) && activeForm.form_data[inputIdentifier].value) {
                addresses.push(activeForm.form_data[inputIdentifier].value);
            }
        }

        return addresses;
    };

    const sendMail = (emailList: string) => {
        const payload = {
            emails: emailList,
            hash: match.params.chartHash
        };
        const config = getAxiosConfigWithToken(token);

        axios.post(API_MAIL, payload, config)
            .then((response) => {
                if (response.data.success === true) {
                    mailSuccess(payload);
                } else {
                    console.error('[Email.tsx] API_MAIL', response);
                    showToast('Az email küldés sikertelen volt. Hiba üzenet: ' + getErrorMessageFromResponse(response));
                }
            })
            .catch(() => showToast('Az email küldés sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'));
    };

    const mailSuccess = (payload: any) => {
        showSuccessAlert('Az email sikeresen elküldve.');
        props.history.push('/charts');
    };

    const inputHandler = (event: any, inputIdentifier: number) => {
        inputChangedHandler(event.target.value, inputIdentifier, activeForm, updateActiveForm);
    };

    const setEmailValue = (value: string) => isEmail(value) ? value : '';

    const chartForm = () => {
        if (!isCurrentFormLoaded) {
            return <Spinner/>;
        }

        return <ChartForm
            form={activeForm}
            inputChangedHandler={inputHandler}
            formIsValid={true}
            onSaveHandler={onSaveHandler}
            onCancelHandler={onCancelHandler}
            saveButtonText={'Küldés'}
            {...props}
        />;
    };

    const onCancelHandler = () => {
        props.history.push('/charts')
    };

    return (
        <IonPage>
            <IonContent>
                <Header/>

                {chartForm()}
            </IonContent>
        </IonPage>
    );
};

export default Email;
