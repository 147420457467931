import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IonButton, IonContent, IonPage, IonSpinner} from "@ionic/react";

import * as actions from '../../store/actions';
import {useFilesystem} from "@ionic/react-hooks/filesystem";

const Sync: React.FC = () => {
    const isSyncError: string = useSelector((state: any) => state.sync.error);
    const isSyncLoading: boolean = useSelector((state: any) => state.sync.loading);
    const lastSyncDate: number = useSelector((state: any) => state.sync.lastSyncDate);

    const dispatch = useDispatch();
    const startSync = () => dispatch(actions.startTwoWaySync(readFile));
    const {readFile} = useFilesystem();

    const syncButtonHandler = () => {
        startSync();
    };

    const getDate = lastSyncDate ? new Date(lastSyncDate).toLocaleString() : null;

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1 className="ion-padding-bottom">Szinkronizáció</h1>
                {getDate && <h5>Utolsó szinkronizáció dátuma: {getDate}</h5>}
                {isSyncLoading ? <IonSpinner/> : null}
                {isSyncError ? <p>A szinkronizáció sikertelen volt. Próbálja meg később.</p> : null}
                <IonButton onClick={syncButtonHandler}>Szinkronizáció indítása</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default Sync;
