import * as actions from "../../store/actions";
import {authLoadingEnd, checkAuthTimeout, updateAuth} from "../../store/actions/auth";

export const initAuthFromStorage = (dispatch: any, auth: any) => {
    return new Promise((resolve: any) => {
        const expirationDate = new Date(String(auth.expirationDate));
        console.log('[functions/auth.ts] initAuthFromStorage');

        if (isExpired(expirationDate)) {
            console.log('[functions/auth.ts] auth expired');
            dispatch(actions.logout());
            return;
        }

        console.log('[functions/auth.ts] init full data from storage');
        const initUser = dispatch(actions.initUserFromStorage());
        const initEvent = dispatch(actions.initEventFromStorage());
        const initChart = dispatch(actions.initChartsFromStorage());

        dispatch(actions.authSuccess(auth.token, expirationDate));
        dispatch(actions.checkAuthTimeout(expiresIn(expirationDate)));

        Promise.all([initUser, initEvent, initChart]).then(_ => {
            console.log('[functions/auth.ts] authLoadingEnd');
            resolve();
        });
    });
};

export const onAuthSuccessRequest = (dispatch: any, response: any) => {
    console.log('[functions/auth.ts] onAuthSuccessRequest init');
    const idToken = response.data.payload.idToken;
    const expiresIn = response.data.payload.expiresIn;
    const expirationDate = new Date(
        new Date().getTime() + expiresIn * 1000
    );

    dispatch(checkAuthTimeout(expiresIn));

    dispatch(updateAuth(idToken, expirationDate)).then(() => {
        console.log('[functions/auth.ts] updateAuth done');
        const syncGet = dispatch(actions.getSyncDataFromServer(idToken));
        const initChartsFromStorage = dispatch(actions.initChartsFromStorage());

        Promise.all([syncGet, initChartsFromStorage]).then(_ => {
            dispatch(authLoadingEnd());
        });
    });
};

export const isExpired = (expirationDate: Date) => {
    return expirationDate <= new Date();
};

export const expiresIn = (expirationDate: Date) => {
    return String((expirationDate.getTime() - new Date().getTime()) / 1000);
};
